import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-5907_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR IN-5907 HD Product Features",
  "path": "/Outdoor_Cameras/IN-5907_HD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-5907 HD is weather proof and wireless outdoor camera. Thanks to the built-in high-powered infrared diodes you can see deep into the night.",
  "image": "./P_SearchThumb_IN-5907HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-5907HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-5907HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR IN-5907 HD Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-5907 HD is weather proof and wireless outdoor camera. Thanks to the built-in high-powered infrared diodes you can see deep into the night.' image='/images/Search/P_SearchThumb_IN-5907HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-5907HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-5907_HD/Produkt_Features/' locationFR='/fr/Outdoor_Cameras/IN-5907_HD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-5907-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-5907-full-hd-manual",
        "aria-label": "in 5907 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-5907 Full HD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01bb856c8a01ce05f104314256d71bc6/573d3/IN-5907_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABrklEQVQoz4WSW2sTQRiG94eJd/4DL7wRvek/0LsiaoJtldTWQC0VtZBeqAilVhRb18ZDFEM2mtMWhbQb0l1d1y5tzW7onh6ZaTckKjgwwzDzfs+8884oSZLwvxZFEf/ShVFEEIZynu4rYvB8H9O06Hk+7t4+P5xdvtkOXfM7P929EWCv57FjWqxvFMnl8ty9V5DgFCqB07NznD0/xuozlbKmU3yv8UGrU642KX9qyoJ+v8/yyir3FwvMzM4xfilLdmKGa7k8Bwe/JDCO4yOg+rrE6TPnuJq5Qbmi87G6iVb/Sq21RduwpFC4XF55ypXMdSanbnF7fomb+UUePn4yEoMSxUdXUYvvKBQeYXRsTGuXRqtN64tBEEYSljqo1Zq0tww8z+dyJsdL9Q2uu8/bkoZW1VHiJCE+zkeM6QEiu65l4/cPCYJQ5jP8MLq+idHpchhExHHCq40S83ceoAiNEIrFtGj4gPSVhTvRtrc7XLg4zomTp8hOTNGoNwYax3FFhsdlqQMJS0YcDQNt26FS+czzNZXphQVerK0P9mSGqcORDn8B/7zy4I+KbIdqfwOzfpVKt1qTuAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01bb856c8a01ce05f104314256d71bc6/e4706/IN-5907_Overview_width1650.avif 230w", "/en/static/01bb856c8a01ce05f104314256d71bc6/d1af7/IN-5907_Overview_width1650.avif 460w", "/en/static/01bb856c8a01ce05f104314256d71bc6/7f308/IN-5907_Overview_width1650.avif 920w", "/en/static/01bb856c8a01ce05f104314256d71bc6/e1c99/IN-5907_Overview_width1650.avif 1380w", "/en/static/01bb856c8a01ce05f104314256d71bc6/e02f7/IN-5907_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01bb856c8a01ce05f104314256d71bc6/a0b58/IN-5907_Overview_width1650.webp 230w", "/en/static/01bb856c8a01ce05f104314256d71bc6/bc10c/IN-5907_Overview_width1650.webp 460w", "/en/static/01bb856c8a01ce05f104314256d71bc6/966d8/IN-5907_Overview_width1650.webp 920w", "/en/static/01bb856c8a01ce05f104314256d71bc6/445df/IN-5907_Overview_width1650.webp 1380w", "/en/static/01bb856c8a01ce05f104314256d71bc6/44758/IN-5907_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01bb856c8a01ce05f104314256d71bc6/81c8e/IN-5907_Overview_width1650.png 230w", "/en/static/01bb856c8a01ce05f104314256d71bc6/08a84/IN-5907_Overview_width1650.png 460w", "/en/static/01bb856c8a01ce05f104314256d71bc6/c0255/IN-5907_Overview_width1650.png 920w", "/en/static/01bb856c8a01ce05f104314256d71bc6/b1001/IN-5907_Overview_width1650.png 1380w", "/en/static/01bb856c8a01ce05f104314256d71bc6/573d3/IN-5907_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01bb856c8a01ce05f104314256d71bc6/c0255/IN-5907_Overview_width1650.png",
              "alt": "INSTAR IN-5907 HD",
              "title": "INSTAR IN-5907 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-5907 HD is weather proof and wireless outdoor camera. Built-in, high-powered infrared diodes allow you to see deep into the dark. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`MicroSD Memory Card`}</Link>{` with comparably small size but best picture results.`}</p>
    <p>{`For surveillance purposes, the IN-5907 HD is ready for implementation to your existing system. But also as a stand-alone device it offers you a number of alarming features like Email Notification or compatibility with `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Signalling Devices`}</Link>{`.`}</p>
    <p>{`Many built-in features allow you to use this camera not only for surveillance, but also for your personal projects. The `}<Link to="/Motion_Detection/Alarm_FTP_Upload/" mdxType="Link">{`FTP Support`}</Link>{` allows you to use the IN-5907 HD as a multi-functional outdoor webcam for integration on your website.`}</p>
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      